<template>
  <v-card>
    <loading-bar :value="loading"/>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-end py-0">
          <v-btn
            color="color3"
            text x-small
            @click.stop="window = window === 0 ?  1 : 0"
          >
            {{window === 0 ?  'auto' : 'manual'}} merge
          </v-btn>
        </v-col>
      </v-row>
      <v-window v-model="window">
        <v-window-item :key="0">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                color="color3"
                append-icon="fas fa-search"
                label="Search"
                hide-details
                v-model="search"
                solo-inverted
                clearable
              ></v-text-field>
            </v-col>
            <!-- Unmerged Teams -->
            <v-col cols="12" sm="6">
              <v-toolbar color="color1 color1Text--text" dense>
                <v-toolbar-title>Unmerged Teams</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :items="teams.singles"
                :headers="headers"
                :options.sync="page"
                :search="search"
                dense
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item="{item}">
                  <tr v-if="$vuetify.breakpoint.xs">
                    <td :colspan="3">
                      <table class="nested">
                        <tr>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <th>Division:</th>
                          <td>{{item.division.name}}</td>
                        </tr>
                        <tr v-if="item.division.numOfPlayers !== 2">
                          <th>Team:</th>
                          <td>{{item.team.name}}</td>
                        </tr>
                        <tr>
                          <th>{{ item.division.numOfPlayers !== 2 ? 'Players:' : 'Team:'}}</th>
                          <td>
                            <template v-for="(player, i) in item.team.players">
                              {{i === 0 ? '' : '/'}}
                              <player-link
                                :player="player"
                                :key="player.id"
                              ></player-link>
                            </template>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>
                        <template v-if="item.team.players.length">
                          <div v-for="player in item.team.players" :key="player.id">
                            <player-link
                              :player="player"
                              :key="player.id"
                            ></player-link>
                          </div>
                        </template>
                        <div v-else>
                          {{item.team.name}}
                        </div>
                      <div v-if="data">{{data[item.team.id]}}</div>
                    </td>
                    <td>{{item.division.name}}</td>
                    <td class="text-right">
                      <v-btn color="color3 color3Text--text" text icon x-small @click.stop="selectTeam(item)">
                        <v-icon>fas fa-user-plus</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <!-- Merged Teams -->
            <v-col cols="12" sm="6">
              <v-toolbar color="color1 color1Text--text" dense>
                <v-toolbar-title>Merged Teams</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :items="teams.pairs"
                :headers="headers2"
                :options.sync="page"
                :search="search"
                class="elevation-1"
                hide-default-footer
                dense
              >
                <template v-slot:item="{item}">
                  <tr v-if="$vuetify.breakpoint.xs">
                    <td :colspan="3">
                      <table class="nested">
                        <tr>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <th>Division:</th>
                          <td>{{item.division.name}}</td>
                        </tr>
                        <tr v-if="item.division.numOfPlayers !== 2">
                          <th>Team:</th>
                          <td>{{item.team.name}}</td>
                        </tr>
                        <tr>
                          <th>{{ item.division.numOfPlayers !== 2 ? 'Players:' : 'Team:'}}</th>
                          <td>
                            <template v-for="(player, i) in item.team.players">
                              {{i === 0 ? '' : '/'}}
                              <player-link
                                :player="player"
                                :key="player.id"
                              ></player-link>
                            </template>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>{{item.team.name}}</td>
                    <td>{{item.division.name}}</td>
                    <td>
                      <div v-for="(player, i) in item.team.players" :key="player.id">
                        {{i === 0 ? '' : '/'}}
                        <player-link
                          :player="player"
                          :key="player.id"
                        ></player-link>
                      </div>
                    </td>
                    <td class="text-right">
                      <v-btn
                        color="color3 color3Text--text"
                        text
                        x-small
                        :loading="loadingUnMerge"
                        @click.stop="undo(item)"
                      >undo</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-row dense>
                <v-col cols="12" class="text-center">
                  <v-btn
                    color="color3 color3Text--text"
                    @click.stop="undoAll"
                    :loading="loadingUnMerge"
                    v-if="teams.pairs.length > 0"
                  >Undo ALL</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :key="1">
          <v-row>
            <v-col cols="12">
              <v-window v-model="subWindow">
                <v-window-item :key="0">
                  <v-card>
                    <v-card-title>
                      Auto Merge Type?
                    </v-card-title>
                    <v-card-text>
                      <v-btn color="success" block class="mb-5" @click.stop="subWindow = 3" >Blind draw</v-btn>
                      <v-btn color="success" block @click.stop="subWindow = 2" :disabled="!hasStandings">From Standings</v-btn>
                    </v-card-text>
                  </v-card>
                </v-window-item>
                <!-- Division -->
                <v-window-item :key="1">
                  <v-card>
                    <v-card-title>
                      Select the Division(s)
                    </v-card-title>
                    <v-card-text>
                      <v-autocomplete
                        :items="divisionChoices"
                        v-model="division"
                        label="Division"
                        persistent-hint
                        item-text="name"
                        return-object
                        color="color3"
                        hide-details
                      ></v-autocomplete>
                    </v-card-text>
                    <v-card-actions class="jusitfy-end">
                      <v-btn
                        color="color3 color3Text--text"
                        fab small
                      >
                        <v-icon>fas fa-caret-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-window-item>
                <!-- Standings -->
                <v-window-item :key="2">
                  <v-card>
                    <v-card-title>
                      Merge Teams From Standings
                    </v-card-title>
                    <v-card-text>
                      <v-autocomplete
                        :disabled="loading"
                        :items="divisionChoices"
                        v-model="division"
                        label="Division"
                        persistent-hint
                        item-text="name"
                        return-object
                        color="color3"
                        hide-details
                        chips small-chips deletable-chips
                      ></v-autocomplete>
                      <v-expand-transition>
                        <v-autocomplete
                          v-if="division"
                          :disabled="loading"
                          :items="standingOptions"
                          v-model="standings"
                          label="Standings"
                          persistent-hint
                          color="color3"
                          item-color="color3"
                          hide-details
                          return-object
                          multiple chips small-chips deletable-chips
                        ></v-autocomplete>

                      </v-expand-transition>
                      <v-expand-transition>
                        <v-autocomplete
                          :disabled="loading"
                          v-if="standings.length"
                          :items="autoMergeOptions"
                          v-model="autoMergeOption"
                          label="Merge Type"
                          persistent-hint
                          color="color3"
                          item-color="color3"
                          hide-details
                          return-object
                          chips small-chips deletable-chips
                        ></v-autocomplete>
                      </v-expand-transition>
                      <v-expand-transition>
                        <div v-if="teamPreview.length > 0" class="mt-3">
                          <div class="title">Team Preview</div>
                          <v-data-table
                            :headers="[
                              { text: 'Seed', value: 'seed' },
                              { text: 'Teams', value: 'teams' }
                            ]"
                            :items="teamPreview"
                            hide-default-footer
                            dense
                            :items-per-page="-1"
                          >
                            <template v-slot:[`item.teams`]="{item}">
                              <span v-for="t in item.teams" :key="t.id">
                                {{t.name}} ({{t.group}} {{t.finish | ordinal}})
                              </span>
                            </template>
                          </v-data-table>
                          <v-btn
                            color="color3 color3Text--text"
                            class="mt-9"
                            small
                            @click.stop="mergFromStandings"
                            :loading="loading"
                          >
                            go Megre Teams
                            <v-icon class="ml-3">fas fa-caret-right</v-icon>
                          </v-btn>
                        </div>
                      </v-expand-transition>
                    </v-card-text>
                    <v-card-actions class="jusitfy-end">
                    </v-card-actions>
                  </v-card>
                </v-window-item>
                <!-- Blind Draw -->
                <v-window-item :key="3">
                  <v-card>
                    <v-card-title>
                      Blind Draw
                    </v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" sm="6" lg="3">
                          <v-autocomplete
                            :disabled="loading"
                            :items="divisionChoices"
                            v-model="blindDivisions"
                            label="Division(s)"
                            persistent-hint
                            item-text="name"
                            item-value="id"
                            color="color3"
                            item-color="color3"
                            hide-details
                            chips small-chips deletable-chips multiple
                            :menu-props="{ closeOnContentClick: true, closeOnClick: true }"
                          ></v-autocomplete>
                        </v-col>
                        <v-slide-x-transition>
                          <v-col cols="12" sm="3" lg="3" v-if="!!blindDivisions.length">
                            <v-autocomplete
                              :disabled="loading"
                              :items="[2,3,4,5,6]"
                              v-model="playerCount"
                              label="Teams Of"
                              persistent-hint
                              color="color3"
                              item-color="color3"
                              hide-details
                            ></v-autocomplete>
                          </v-col>
                        </v-slide-x-transition>
                        <v-slide-x-transition>
                          <v-col cols="12" sm="6" lg="3" v-if="blindDivisions.length > 1">
                            <v-switch
                              label="Alternate Divisions During Selection"
                              v-model="altDiv"
                              color="success"
                            ></v-switch>
                          </v-col>
                        </v-slide-x-transition>
                      </v-row>
                      <v-expand-transition>
                        <v-row dense>
                          <v-col cols="12">
                            <v-alert type="info" :value="true" v-if="remainder && !loading" text>
                              This will leave {{remainder}} player/team unmerged
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-expand-transition>
                      <v-expand-transition>
                        <v-row dense v-if="!!blindDivisions.length">
                          <v-col cols="12">
                            <v-btn
                              color="color3 color3Text--text"
                              @click.stop="doBlindDraw"
                              :disabled="loading"
                            >
                              {{teamPreview.length ? 'Re-' : ''}}Draw Teams
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expand-transition>
                      <v-expand-transition>
                        <v-row dense v-if="teamPreview.length > 0">
                          <v-col cols="12">
                            <div class="title">Team Preview</div>
                            <v-data-table
                              :headers="[
                                { text: 'Seed', value: 'seed' },
                                { text: 'Teams', value: 'teams' }
                              ]"
                              :items="teamPreview"
                              hide-default-footer
                              dense
                              :items-per-page="-1"
                            >
                              <template v-slot:[`item.teams`]="{item}">
                                <span v-for="(t, i) in item.teams" :key="t.id">
                                  {{`${i > 0 ? ' & ' : ''}${t.team.name}`}}{{blindDivisions.length > 1 ? ` (${t.division.name})` : ''}}
                                </span>
                              </template>
                            </v-data-table>
                          </v-col>
                          <v-col cols="12" v-if="divisionChoices.length > 1">
                            <v-select
                              label="Merge teams Into the following division:"
                              v-model="division"
                              :items="divisionChoices"
                              color="color3"
                              item-color="color3"
                              item-text="name"
                              return-object
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              color="color3 color3Text--text"
                              class="mt-9"
                              small
                              @click.stop="mergFromStandings"
                              :loading="loading"
                            >
                              go Megre Teams
                              <v-icon class="ml-3">fas fa-caret-right</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expand-transition>
                    </v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-container>
    <v-bottom-sheet
      v-model="open"
      hide-overlay
      :inset="$vuetify.breakpoint.mdAndUp"
      persistent
    >
      <v-card color="grey lighten-3">
        <v-toolbar dense color="color2 color2Text--text">
          <v-toolbar-title>Team Creator</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            fab small
            @click.stop="open = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="subheading">
          To add another registered player to this team: search the player table and click the add player button
          <v-container fluid class="pa-0">
            <v-row dense>
              <v-text-field
                v-if="open"
                color="color3"
                append-icon="fas fa-search"
                label="Search"
                hide-details
                v-model="search"
                solo-inverted
                clearable autofocus
              ></v-text-field>
            </v-row>
            <v-row dense>
              <v-col v-for="(player, i) in selectedPlayers" :key="player.id" cols="6" md="4" lg="3">
                <v-card class="pa-2 fill-height">
                  <div>{{player.name}}</div>
                  <div style="font-size: small">{{player.club}}</div>
                  <div style="font-size: x-small">{{player.cityState}}</div>
                </v-card>
                <div class="text-right">
                  <v-btn
                    color="error"
                    text
                    class="xsmall pa-0 my-0"
                    @click.stop="remove(i)"
                  >Remove</v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="9" sm="6" md="4" style="min-height: 64px">
                <v-autocomplete
                  :items="divisionChoices"
                  v-model="division"
                  label="Division"
                  persistent-hint
                  item-text="name"
                  item-value="id"
                  color="color3"
                  hide-details
                ></v-autocomplete>

              </v-col>
              <v-col cols="9" sm="6" md="4" style="min-height: 64px">
                <v-text-field
                  label="Team Name"
                  v-model="teamname"
                ></v-text-field>

              </v-col>
              <v-col class="text-right" cols="3" sm="6" md="4" >
                <v-fab-transition>
                  <v-btn
                    color="success whiteText--text"
                    class="ma-0"
                    fab
                    v-if="valid"
                    @click.stop="saveTeam"
                    :loading="loading"
                  >
                    <v-icon>fas fa-save</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <vbl-confirm-dialog
      :ask="unMerge.length > 0"
      @deny="unMerge = []"
      @confirm="doUnMerge"
      :loading="loadingUnMerge"
    ></vbl-confirm-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import PlayerLink from '@/components/Player/PlayerLink.vue'
import flatten from '@/helpers/ArrayFlatten'
import { firstBy } from 'thenby'
import shuffle from 'shuffle-array'

export default {
  props: ['active'],
  data () {
    return {
      page: { itemsPerPage: -1, sortBy: ['division.name'] },
      window: 1,
      search: null,
      loading: false,
      combine: [],
      division: null,
      data: null,
      teamname: null,
      unMerge: [],
      loadingUnMerge: false,
      subWindow: 0,
      standings: [],
      autoMergeOption: null,
      teamPreview: [],
      playerCount: 2,
      blindDivisions: [],
      altDiv: true
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'getTeam']),
    hasStandings () {
      return !!this.tournament.publicDivisions.find(f => f.standings.length > 0)
    },
    savedStandings () {
      return this.division && this.division.standings
    },
    standingOptions () {
      return this.savedStandings ? flatten(this.division.standings.map((s, i) => {
        return s.groups.map((g, i2) => {
          return {
            id: null,
            text: `${s.title}: ${g.name} ${g.locked ? '' : ' - (Need to lock)'}`,
            i: `${i}-${i2}`,
            g: g,
            disabled: !g.locked
          }
        })
      })) : []
    },
    autoMergeOptions () {
      return [
        { text: 'Top down', id: 1 }
      ]
    },
    valid () {
      return this.combine.length > 1 && this.division
    },
    dto () {
      return this.valid && {
        name: this.teamname,
        teamIds: this.combine.map(m => {
          return m.team.id
        }),
        divisionId: this.division
      }
    },
    autoDto () {
      return this.teamPreview.map(m => {
        return {
          name: null,
          seed: m.seed,
          teamIds: m.teams.map(t => t.id || t.team.id),
          divisionId: this.division.id
        }
      })
    },
    open: {
      get: function () {
        return this.combine.length > 0
      },
      set: function (val) {
        if (!val) {
          this.combine = []
        }
      }
    },
    selectedPlayers () {
      return flatten(this.combine.map(m => m.team.players.length > 0 ? m.team.players : [{ id: m.team.id, name: m.team.name }]))
    },
    headers2 () {
      return [
        { text: 'Team', value: 'team.name' },
        { text: 'Division', value: 'division.name' },
        { text: 'Players', value: 'team.playerNames' },
        { text: '', sortable: false }
      ]
    },
    headers () {
      return [
        { text: 'Player', value: 'team.playerNames' },
        { text: 'Division', value: 'division.name' },
        { text: '', sortable: false }
      ]
    },
    teams () {
      return {
        all: this.tournament.teamsOverview,
        singles: this.tournament.teamsOverview.filter(f => !f.team.tags.includes('merge-result') && !this.combine.map(m => { return m.team.id }).includes(f.team.id)),
        pairs: this.tournament.teamsOverview.filter(f => f.team.tags.includes('merge-result'))
      }
    },
    divisionChoices () {
      return this.tournament.divisions.filter((d) => {
        return !d.canceled
      })
    },
    fromStandings () {
      return this.subWindow === 2
    },
    blindTeams () {
      var base = this.teams.singles
      if (this.blindDivisions) {
        base = base.filter(f => this.blindDivisions.includes(f.division.id))
      }
      return base
    },
    remainder () {
      return this.blindTeams && this.blindTeams.length % this.playerCount
    }
  },
  methods: {
    saveTeam () {
      if (!this.valid) return
      this.loading = true
      this.$VBL.team.merge([this.dto])
        .then(r => {
          this.open = false
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    selectTeam (team) {
      const x = this.combine.find(f => f.id === team.team.id)
      if (!x) {
        this.combine.push(team)
        if (this.combine.length === 1) {
          this.division = team.division.id
        }
        this.search = null
      }
    },
    undo (team) {
      this.unMerge = [team.team.id]
    },
    undoAll () {
      this.unMerge = this.teams.pairs.map(m => m.team.id)
    },
    doUnMerge () {
      this.loadingUnMerge = true
      this.$VBL.team.undoMerge(this.unMerge)
        .then(r => {
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loadingUnMerge = false
          this.unMerge = []
        })
    },
    remove (i) {
      this.combine.splice(i, 1)
    },
    getRegData () {
      this.loading = true
      this.$VBL.team.getPartnerData(this.tournament.id)
        .then((response) => {
          this.data = response.data
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    autoMergePreview () {
      this.teamPreview = []
      this.division && this.fromStandings && this.standings.length && this.autoMergeOption && this.autoMergeOption.id === 1 && this.topDownFromStandings()
    },
    doBlindDraw () {
      this.teamPreview = []
      this.division = this.divisionChoices.find(f => f.id === this.blindDivisions[0])
      const base = this.blindDivisions.map(m => {
        return shuffle(this.blindTeams.filter(f => f.division.id === m))
      })
      let seed = 1
      let teamI = 0
      let baseI = 0
      console.log('here')
      const teams = this.blindTeams.length / this.playerCount
      for (teamI; teamI < teams; teamI++) {
        const mergedTeam = { seed: seed++, teams: [] }
        let playerI = 0
        for (playerI; playerI < this.playerCount && base.length > 0; playerI++) {
          const p = base[baseI] && base[baseI].length && base[baseI].shift()
          if (p) {
            mergedTeam.teams.push(p)
          }
          if (base[baseI] && base[baseI].length === 0) {
            base.splice(baseI, 1)
          } else {
            baseI++
          }
          if (baseI >= base.length) baseI = 0
        }
        this.teamPreview.push(mergedTeam)
      }
    },
    topDownFromStandings () {
      console.log('here')
      const groups = this.standings.map(s => {
        return s.g.summary.map(g => {
          const t = this.getTeam(g.teamId)
          return {
            id: g.teamId,
            group: s.g.name,
            finish: g.finish,
            name: t.name,
            deleted: t.isDeleted
          }
        }).filter(f => !f.deleted).sort(firstBy('finish'))
      })
      const take = groups.length === 1 ? 2 : 1
      let test = !groups.find(f => f.length < take)
      let seed = 1
      while (test) {
        const mergedTeam = { seed: seed++, teams: [] }
        groups.forEach(g => {
          var s = g.splice(0, take)
          mergedTeam.teams.push(...s)
        })
        this.teamPreview.push(mergedTeam)
        test = !groups.find(f => f.length < take)
      }
    },
    mergFromStandings () {
      this.loading = true
      this.$VBL.team.merge(this.autoDto)
        .then(r => {
          this.window = 0
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.getRegData()
      }
    },
    autoMergeOption: function (val) {
      if (val) {
        this.autoMergePreview()
      } else {
        this.teamPreview = []
      }
    },
    window: function (val) {
      this.division = null
      this.subWindow = 0
      this.standings = []
      this.autoMergeOption = null
      this.teamPreview = []
    }
  },
  components: {
    PlayerLink
  }
}
</script>

<style scoped>
.nested th, .nested td {
  padding: 0 !important;
  height: auto !important;
}
.nested th {
  text-align: right;
}
</style>
